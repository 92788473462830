import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PharmaciesService {

  endpoint: string = 'pharmacy';
  pharmacies: any[] = [];
  selectedPharmacy: any = null;

  constructor(
    private http: HttpClient,
  ) { }

  getPharmacies(){
    this.endpoint = 'pharmacy';
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(
        map((response: any) => {
          this.pharmacies = response;
          return response;
        })
      );
  }

  getPharmacy(id: number){
    this.endpoint = 'pharmacy';
    return this.http.get(`${environment.apiUrl}/${this.endpoint}/${id.toString()}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  newPharmacy(farmacia: any){
    this.endpoint = 'pharmacy';
    return this.http.post(`${environment.apiUrl}/${this.endpoint}`, farmacia)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updatePharmacy(id: number, farmacia: any){
    this.endpoint = 'pharmacy';
    return this.http.put(`${environment.apiUrl}/${this.endpoint}/${id.toString()}`, farmacia)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }



}
